import styled from "styled-components";
import {
  BP,
  Rem,
  Colors,
} from "../../commons/Theme";

export const StyledContactForm = styled.div`
  padding: 3rem 0;
  background: ${Colors.grayLight};
  @media (${BP.ipad}) {
    padding: 6rem 0;
  }
`;

export const StyledFormLabel = styled.label`
`;

export const StyledContactFormSubmit = styled.div`
  position: relative;
  padding-top: ${Rem(30)};
  display: flex;
  justify-content: center;

  @media (${BP.ipad}) {
    padding-top: ${Rem(60)};
  }
`;

export const StyledFormTItle = styled.div`
  font-size: ${Rem(20)};
  text-align: center;
  padding-bottom: 3rem;
  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`;
